import React from "react";

import { Link } from "gatsby";

import Layout from "../components/layout";
import PurpleRow from "../components/purpleRow";

import ICMail from "../assets/images/icons/ic_mail.png";
import ICPhone from "../assets/images/icons/ic_phone.png";
import ICFb from "../assets/images/icons/ic_facebook.png";


const ContactPage = () => {

  const onSend = (e) => {
    var url = "https://devsystem.kidsup.pl/contact";
    $(".okmessage").hide();
    $(".errormessage").hide();
    $.ajax({
      type: "POST",
      url: url,
      data: $("#contact_form").serialize(),
      success: function (data) {
        if (data == "OK") {
          $(".okmessage").toggle();
        } else {
          $(".errormessage").toggle();
        }
      },
    });
    e.preventDefault();
    return false;
  };

  return (
    <Layout title="Kontakt">
      <div className="wrapper">
        <div className="section section-presentation">
          <div className="container">
            <div className="row">
              <div className="opinions-main-wrapper">
                <div className="col-sm-12 contact-main-text">
                  <h2>
                    <span className="purple">kids</span>
                    <span className="rose">up</span> – dla tych, którzy chcą
                    lepiej
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section " id="contact">
        <div className="grey-wrapper"></div>

        <div className="container text-center">
          <div className="row middle-flex middle-flex-col">
            <div className="col-md-6 col-xs-12 section-contact-info">
              <div className="info-part">
                <a className="info-element" href="mailto:kontakt@kidsup.pl">
                  <img src={ICMail} /> kontakt@kidsup.pl
                </a>
                <div className="info-element">
                  <a href="https://www.facebook.com/KidsUp-aplikacja-dla-rodzica-przedszkolaka-1959494441007964/">
                    <img src={ICFb} /> Facebook
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xs-12 middle-flex">
              <form
                id="contact_form"
                action="#"
                method="POST"
                encType="multipart/form-data"
                onSubmit={onSend}
              >
                <div className="row" style={{ display: "flex" }}>
                  <input
                    id="name"
                    className="input input_field input-half"
                    required
                    name="name"
                    type="text"
                    size="30"
                    placeholder="Imię"
                    style={{ marginRight: "15px" }}
                  />
                  <br />
                  <input
                    id="lastname"
                    className="input input_field input-half"
                    required
                    name="lastname"
                    type="text"
                    size="30"
                    placeholder="Nazwisko"
                  />
                  <br />
                </div>
                <div className="row">
                  <input
                    id="email"
                    className="input input_field"
                    required
                    name="email"
                    type="email"
                    placeholder="Adres email"
                    size="30"
                  />
                  <br />
                </div>
                <div className="row">
                  <textarea
                    id="message"
                    required
                    className="input input_field"
                    style={{ height: "100px" }}
                    name="message"
                    placeholder="Wiadomość"
                    rows="7"
                    cols="30"
                  ></textarea>
                  <br />
                  <p className="contact-privacy">
                    *Dane w formularzu będą przetwarzane wyłącznie w celu
                    obsługi twojego zapytania. Szczegóły znajdziesz w{" "}
                    <Link to="/prywatnosc">polityce prywatności.</Link>
                  </p>
                </div>
                <div
                  className="okmessage alert alert-success"
                  style={{ display: "none" }}
                >
                  Wiadomość wysłana
                </div>
                <div
                  className="errormessage alert alert-danger"
                  style={{ display: "none" }}
                >
                  Wiadomość nie została wysłana - spróbuj ponownie później
                </div>
                <input
                  className="btn btn-fill btn-neutral send-btn contact-send-btn"
                  id="submit_button"
                  type="submit"
                  value="Wyślij"
                />
              </form>
            </div>
          </div>
        </div>
      </div>

      <PurpleRow>
        Aplikacja dostosowana <br />
        do potrzeb Twojego przedszkola
      </PurpleRow>
    </Layout>
  );
};

export default ContactPage;
